/** 
 * Use as external script from public-api folder
 * requires jquery
 */

import '../../../scss/booking-widget/v2/booking-widget.scss';
import { init as init_token } from 'data/token';
import { load as load_step, setup_listeners, merge_options_with_url } from 'booking-widget/v2/bw';
import { load_js } from 'utils/load';
import { setup_maintenance_trigger } from 'booking-widget/v2/common/util';
import { get_all as get_booking_settings, get_notice } from 'data/booking_setting';
import { get_settings as get_agency_settings } from 'data/agency';
import { get as _get_search_tags } from 'data/tags';
import { get as get_cache, cache } from 'utils/cache';

(function ( $ ) {
    let default_op = function() { location.href = 'https://www.napali.com/'; }
    
    $.fn.bw_setup_token = function(options) {
        init_token({
            on_done: function() {
                $(document).trigger('bw-token-initialized', {url: URL_ROOT});
            }
        })
    };

    $.fn.bw_setup_datepicker = function(options) {
        load_datepicker();
    };
    
    $.fn.booking_widget = function(options) {
        if (typeof options === 'undefined') {
            options = {};
        }

        let el = this;
        let opts = {
            identifier: 'bw1',
            context_el: el,
            search_header: false,
            search_selector: false,
            search_selector_compact: false,
            search_tag_slug: false,
            utc_offset: -10,
            handle_gc: false,
            filter_tour_id: false,
            is_tour_specific: false,
            is_charter: false,
            is_raft_only: false,
            faq_url: URL_ROOT_PUBLIC + 'faq/',
            on_search_step_loaded: function() {},
            on_searched: function() {},
            on_tours_step_loaded: function() {},
            on_info_step_loaded: function() {},
            on_info_submit: function() {},
            on_payment_step_loaded: function() {},
            on_payment_submit: function() {},
            on_waitlist_step_loaded: function() {},
            on_waitlist_submit: function() {},
            on_booked: function() {},
            on_trip_selected: function() {},
            on_gc_loaded: function() {},
            on_gc_submitted: function() {},
            server_error_message: 'We apologize for the inconvenience. Our server is temporarily unavailable. Please try again later'
        };

        Object.keys(opts).forEach(key => {
            if (options.hasOwnProperty(key)) {
                opts[key] = options[key];
            }
        });

        if (!options.search_tag_slug) {
            const bw_search_tag_slug = el.attr('data-bw-search-tag-slug');
            if (bw_search_tag_slug) {
                opts.search_tag_slug = bw_search_tag_slug;
            }
        }

        if (!options.is_raft_only) {
            const bw_is_raft_only = el.attr('data-bw-is-raft-only');
            if (typeof bw_is_raft_only !== 'undefined') {
                opts.is_raft_only = bw_is_raft_only;
            }
        }

        setup_maintenance_trigger(opts);

        load_datepicker();

        let booking_settings_params = opts.is_tour_specific ? {data: {tour_id: opts.tour_id} } : null;
        init_token({
            on_done: function() {
                $(document).trigger('bw-token-initialized', {url: URL_ROOT});
                $.when(
                    get_notice(),
                    get_agency_settings(), 
                    get_booking_settings(booking_settings_params),
                    get_search_tags(opts.search_tag_slug)
                ).then(function(notice_json, agency_now_cached, booking_now_cached, tags_now_cached) {
                    if (notice_json.data && notice_json.data.message && notice_json.data.message != '') {
                        $(document).trigger('bw-notice', {
                            message: notice_json.data.message
                        });
                    }

                    opts.search_tag = false;
                    if (opts.search_tag_slug !== false && tags_now_cached.data) {
                        opts.search_tag = tags_now_cached.data.length > 0 ? tags_now_cached.data[0] : {
                            name: 'Available',
                            id: 0
                        };
                    }
                    start(opts);
                });
            }
        });

        return el;
    }

    function start(opts) {
        cache('options', opts);
        setup_listeners(opts);

        // parse the url to get to a step
        let url_opts = merge_options_with_url(opts);
        url_opts.is_first_load = true;

        // do whatever now
        load_step(url_opts);

    }

    function load_datepicker() {
        // dynamically load pikaday so it's not flagged on pagespeed
        load_js({
            url: 'https://cdnjs.cloudflare.com/ajax/libs/pikaday/1.8.2/pikaday.min.js',
            on_ready: function() {
                $(document).trigger('bw-pikaday-loaded');
            }
        });
    }

    function get_search_tags(search_tag_slug) {
        let d = $.Deferred();
        if (search_tag_slug === false) {
            return false;
        }
        const cached = get_cache('search-tag-' + search_tag_slug);
        if (cached !== false) {
            d.resolve(cached);
            return d.promise();
        }
        
        _get_search_tags({
            filters:'slug.eq(' + search_tag_slug + ')',
            on_done: function(json) {
                cache('search-tag-' + search_tag_slug, json);
                d.resolve(json);
            }
        });

        return d.promise();
    }

    
}( jQuery ));