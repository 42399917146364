// ts-popup
// Popup will be appened to opener_el so user can scroll past the popup.
// add data-ts-popup-open=<id> to elements to open popup
// add data-ts-popup-close to elements within a popup to close it.
// as lean as can be, pass in your own html.
/*
*/
/*
html_template = `
<div class="ts-popup" id="[popup_id]">
    <div class="ts-popup__overlay" tabindex="-1" data-ts-popup-close>
        <div class="ts-popup__container" role="dialog" aria-popup="true">
            <header>[popup_title]</header>
            <main class="ts-popup__content">[popup_content]</main>
            <footer class="ts-popup__footer">[popup_footer]</footer>
        </div>
    </div>
</div>
`;
*/
export const init = (options) => {
    let opts = $.extend({
        html: '', // required
        classes: '',
        on_setup: function() {},
        on_shown: function() {},
        on_hidden: function() {},
    }, (options || {}));

    let el = $(opts.html)
        .addClass(opts.classes)
        .addClass('ts-popup')
        .attr('aria-hidden', true)
    ;
    let body_el = $('body');

    let name = el.attr('data-popup-name');
    if (!name) {
        name = 'ts-popup-0';
    }
    body_el.append(el);

    setup_opener('[data-ts-popup-open="' + name + '"]');

    // with popup within popup setups, this triggers twice on the child popup.  check for is visible
    $('[data-ts-popup-close]', el).on('click', function(e) {
        if (e.target.hasAttribute('data-ts-popup-close') && $(this).closest('.ts-popup').is(':visible')) {
            e.preventDefault();
            e.stopPropagation();
            hide($(this).closest('.ts-popup'));
            return false;
        }
    });

    el
        .on('ts-popup-shown', function(e, params) {
            if (opts.on_shown) {
                opts.on_shown(e, params);
            }
        })
        .on('ts-popup-hidden', function(e) {
            if (opts.on_hidden) {
                opts.on_hidden(el);
            }
        })
    ;

    if (opts.on_setup) {
        opts.on_setup(el);
    }

    function show(opener_el) {
        if (el.is(':visible')) {
            return;
        }
        let container_selector = opener_el.attr('data-ts-popup--container');
        let container_el = !container_selector ? opener_el : $(container_selector);
        container_el.append(el);
        container_el.addClass('ts-popup--showing');

        adjustPosition(opener_el);
        // need to set this up on each show bc it all gets destroyed when hidden.
        el
            .data('on_outside_click', function(e) {
                let popup_el = $(e.target).closest('.ts-popup');
                if (popup_el.length > 0) {
                    return;
                }

                if ($(e.target).closest(opener_el).length > 0) {
                    return;
                }

                hide();
            })
            .show()
            .attr('aria-hidden', false)
        ;

        body_el.attr('data-ts-popup-showing', name)

        el.trigger('ts-popup-shown', { opener_el: opener_el, el: el })

        $(document).on('click', el.data('on_outside_click'));
    }

    function adjustPosition(opener_el) {
        if (el.css('position') != 'absolute') {
            return;
        }
        el.removeClass('above-opener').removeClass('right-opener');

        const spacing = 20;
        const viewport_width = $(window).width();
        const viewport_height = $(window).height();
        const scroll_top = $(window).scrollTop();

        const width = el.width();
        const height = el.height();
        let top = opener_el.position().top + opener_el.height() + spacing;
        let left = opener_el.position().left;
        let bottom = '';
        top += 'px';
        left += 'px';

        // in relation to window
        const el_offset_top = opener_el.offset().top + opener_el.height() + spacing;
        const el_offset_left = opener_el.offset().left;

        // default position is bottom & aligned left
        if (el_offset_left + width > viewport_width) {
            // align right
            left = opener_el.position().left + opener_el.width() - width;
            left += 'px';
            el.addClass('right-opener');
        }
        if (el_offset_top + height > viewport_height + scroll_top){
            // align bottom with opener top
            // for some reason el height changes on the 2nd opening.  so don't depend on el height.
            top = '';
            bottom = (opener_el.parent().height() - opener_el.position().top) + spacing;
            if (bottom > 0) {
                bottom += 'px';
            }
            else {
                // don't reposition
                bottom = '';
            }
            el.addClass('above-opener');
        }
        el.css({
            top: top,
            left: left,
            bottom: bottom
        });
    }
    
    function hide() {
        // hides all popups
        $('.ts-popup').each(function() {
            let popup_el = $(this);
            if (popup_el.attr('aria-hidden') === 'true') {
                return true;
            }
            popup_el
                .hide()
                .attr('aria-hidden', true)
                .trigger('ts-popup-hidden')
            ;
            popup_el.closest('.ts-popup--showing').removeClass('ts-popup--showing');

            const body_el = $('body');

            body_el.attr('data-ts-popup-showing', null);
        
            body_el.remove(popup_el)
            
            $(document).off('click', popup_el.data('on_outside_click'));
        });
    }
    
    // call this only once per element that opens the popup
    function setup_opener(opener_selector) {
        let body_el = $('body');
        body_el
            .off('click', opener_selector)
            .on('click', opener_selector, function(e) {
                e.preventDefault();
                
                let popup_showing_name = body_el.attr('data-ts-popup-showing');

                if (popup_showing_name) {
                    console.log('popup_showing_name', popup_showing_name)
                    hide();
                }

                // show this popup if it wasn't open
                if (popup_showing_name != name) {
                    console.log('name', name)
                    show($(this));
                }
            });
    }
}


export const hide = (ts_popup_el) => {
    // hides popups
    ts_popup_el
        .hide()
        .attr('aria-hidden', true)
        .trigger('ts-popup-hidden')
    ;
    ts_popup_el.closest('.ts-popup--showing').removeClass('ts-popup--showing');

    const body_el = $('body');

    body_el.attr('data-ts-popup-showing', null);

    body_el.remove(ts_popup_el)

    $(document).off('click', ts_popup_el.data('on_outside_click'));
}

export const destroy_popup = () => {
    $('.ts-popup').each(function() {
        let popup_el = $(this);
        $(document).off('click', popup_el.data('on_outside_click'));
        popup_el.empty().detach().remove();
    });
}